exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-action-js": () => import("./../../../src/pages/auth/action.js" /* webpackChunkName: "component---src-pages-auth-action-js" */),
  "component---src-pages-auth-delete-account-js": () => import("./../../../src/pages/auth/delete-account.js" /* webpackChunkName: "component---src-pages-auth-delete-account-js" */),
  "component---src-pages-data-usage-js": () => import("./../../../src/pages/data-usage.js" /* webpackChunkName: "component---src-pages-data-usage-js" */),
  "component---src-pages-help-center-already-purchased-no-premium-js": () => import("./../../../src/pages/help-center/already-purchased-no-premium.js" /* webpackChunkName: "component---src-pages-help-center-already-purchased-no-premium-js" */),
  "component---src-pages-help-center-cancel-subscription-js": () => import("./../../../src/pages/help-center/cancel-subscription.js" /* webpackChunkName: "component---src-pages-help-center-cancel-subscription-js" */),
  "component---src-pages-help-center-delete-account-js": () => import("./../../../src/pages/help-center/delete-account.js" /* webpackChunkName: "component---src-pages-help-center-delete-account-js" */),
  "component---src-pages-help-center-how-to-save-progress-js": () => import("./../../../src/pages/help-center/how-to-save-progress.js" /* webpackChunkName: "component---src-pages-help-center-how-to-save-progress-js" */),
  "component---src-pages-help-center-index-js": () => import("./../../../src/pages/help-center/index.js" /* webpackChunkName: "component---src-pages-help-center-index-js" */),
  "component---src-pages-help-center-is-free-js": () => import("./../../../src/pages/help-center/is-free.js" /* webpackChunkName: "component---src-pages-help-center-is-free-js" */),
  "component---src-pages-help-center-multi-language-subscription-js": () => import("./../../../src/pages/help-center/multi-language-subscription.js" /* webpackChunkName: "component---src-pages-help-center-multi-language-subscription-js" */),
  "component---src-pages-help-center-multiple-devices-js": () => import("./../../../src/pages/help-center/multiple-devices.js" /* webpackChunkName: "component---src-pages-help-center-multiple-devices-js" */),
  "component---src-pages-help-center-no-sound-js": () => import("./../../../src/pages/help-center/no-sound.js" /* webpackChunkName: "component---src-pages-help-center-no-sound-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-course-index-js": () => import("./../../../src/templates/Course/index.js" /* webpackChunkName: "component---src-templates-course-index-js" */),
  "component---src-templates-courses-by-lang-index-js": () => import("./../../../src/templates/CoursesByLang/index.js" /* webpackChunkName: "component---src-templates-courses-by-lang-index-js" */),
  "component---src-templates-courses-index-js": () => import("./../../../src/templates/Courses/index.js" /* webpackChunkName: "component---src-templates-courses-index-js" */),
  "component---src-templates-word-index-js": () => import("./../../../src/templates/Word/index.js" /* webpackChunkName: "component---src-templates-word-index-js" */)
}

